import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { AcountService } from 'src/app/services/acount.service';
import { ToastrService } from 'ngx-toastr';
import { Transaction } from 'src/app/models/transaction';
import { es, en } from '../../../assets/i18n/component-translate';
import { SwiperComponent } from "swiper/angular";

// import Swiper core and required modules
import SwiperCore, { Pagination } from "swiper/core";

// install Swiper modules
SwiperCore.use([Pagination]);


declare var $: any;
import Swal from 'sweetalert2';
import { from, timer } from 'rxjs';
import { distinct } from 'rxjs/operators';


@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.css']
})
export class BankComponent implements OnInit {

  transactions: any;
  cards: Array<any> = [];
  total = null;
  email: string;
  cardSelected: any;
  allTransactions: Array<any> = [] ;
  values: Array<any> = [];
  allTotal = null;
  newBank = new Transaction();
  fecha: string;
  loader = true;
  messages;
  timer$ = timer(3000);

  breakpoints = {
    300: {
      slidesPerView: 1,
      spaceBetween: 1
    },
    360: {
      slidesPerView: 1.05,
      spaceBetween: 1
    },

    // when window width is >= 640px
    410: {
      slidesPerView: 1.3,
      spaceBetween: 15
    },

    500: {
      slidesPerView: 1.5,
      spaceBetween: 15
    },
    700: {
      slidesPerView: 1.9,
      spaceBetween: 15
    },
    1000: {
      slidesPerView: 2.5,
      spaceBetween: 15
    },
    1150: {
      slidesPerView: 3,
      spaceBetween: 15
    },
    1300: {
      slidesPerView: 3.1,
      spaceBetween: 15
    }
  }

  constructor(
    private dataService: AcountService,
    private toast: ToastrService,
    ) {
      if (window.navigator.language.split('-')[0] == 'es') {
        this.messages = es;
      } else {
        this.messages = en;
      }

  }

  ngOnInit(): void {
    $(document).ready(() => {
      $('.modal').modal({
        dismissible: false
      });
    });

    this.getDataCards();
    this.getAllTransactions();
  }

  getDataCards() {
    this.dataService.getBank().subscribe((result: any[]) => {
      console.log('Result', result);

      this.cards = result;
      this.loader = false;
      this.cardSelected = this.cards[0];
      this.getTransactions(this.cardSelected);
    }, error => console.log(error));
  }

  getTransactions(card) {
    this.cardSelected = null;
    this.transactions = null;
    this.cardSelected = card;
    if (this.cards.length > 0) {
      this.dataService.getBankPay(card.id).subscribe((result: any) => {
        this.transactions = result;
        this.calcularTotal();
      }, error => console.log(error));
    }
  }

  getAllTransactions() {
    let transactions = [];
    this.dataService.getBank().subscribe((resp: any) => {
      for (const card of resp) {
        this.dataService.getBankPay(card.id).subscribe((response) => {
          from(response).pipe(distinct(t => t.id)).subscribe(tran => {
            if (tran.id) {
              transactions.push(tran);
            }
          });
        });
      }
    });

    this.timer$.subscribe(() => {
      this.allTotal = 0;
      let dataArr = new Set(transactions);
      let result = [...dataArr];
      result.forEach(t => {
        this.allTotal += t.amount;
      });
    });

  }

  calcularTotal() {
    this.allTotal = undefined;
    this.total = 0;
    this.transactions.forEach(transaction => {
      this.total += transaction.amount;
    });
    this.getAllTransactions();
  }

  onDeleteBank(transaccion: Transaction) {
    Swal.fire({
      title: `${this.messages.shure}`,
      text: `${this.messages.deleteIt} ${transaccion.description}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00AEFF',
      cancelButtonColor: '#bdbdbd',
      confirmButtonText: `${this.messages.delete}`,
      cancelButtonText: `${this.messages.cancel}`
  }).then((result) => {

      if (result.value) {
        this.dataService.deleteBank(transaccion.id)
        .then( () => {
          this.toast.success(`${transaccion.description} ${this.messages.deleted} `);
          this.dataService.deleteBankPays(transaccion.id)
        .then(() => {
          this.toast.success(`${this.messages.deletedTransactions}`);
        });
        }).catch( err => {
          this.toast.error('Error');
        });

        this.timer$.subscribe(() => {
          this.getAllTransactions();
        });
      }
    });
  }

  onDeleteBankPay(transaccion: Transaction) {

    Swal.fire({
      title: `${this.messages.shure}`,
      text: `${this.messages.deleteIt} ${transaccion.description}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00AEFF',
      cancelButtonColor: '#bdbdbd',
      confirmButtonText: `${this.messages.delete}`,
      cancelButtonText: `${this.messages.cancel}`
  }).then((result) => {

      if (result.value) {
        this.dataService.deleteBankPay(this.cardSelected.id, transaccion.id)
        .then(() => {
          this.toast.success(`${transaccion.description} ${this.messages.deleted} `);
        }).catch( err => {
          this.toast.error('Error');
        });

        this.timer$.subscribe(() => {
          this.getAllTransactions();
        });
      }
  });
  }

  onSaveBank() {

    const date = new Date(`${this.fecha}`);

    this.newBank.date_timestamp =  date;
    this.newBank.date =  `${this.fecha.split('-')[2]}/${this.fecha.split('-')[1]}/${this.fecha.split('-')[0]}`;

    this.dataService.addBank(this.newBank)
    .then( docRef => {
      this.toast.success(`${this.newBank.description} ${this.messages.added}`);
      this.eliminarDatos();
      docRef.update({
        'id': docRef.id
      });
    })
    .catch(err => {
      this.toast.error('Error');
      console.log(err);
    });

  }

  cancel() {
    this.eliminarDatos();
  }

  eliminarDatos() {
    this.newBank.amount = null;
    this.newBank.description = '';
    this.fecha = null;
  }

  cambio() {
    console.log('Cambio');
  }

  onSwiper(swiper) {
    console.log(swiper);
  }
  onSlideChange(card) {
    this.cardSelected = this.cards[card.activeIndex];
    this.getTransactions(this.cards[card.activeIndex]);

  }
}

