<div class="row" style="margin: 0">

    <div class="info">
        <div class="col s12 l7 animated fadeIn hoverable cont">

            <!-- info -->
            <div>
                <div class="container letra" style="height: 100vh;">

                    <div style="order: 1;color: white !important; ">
                        <h2 style="font-family: tangerine !important; order:1 ; color: white !important; margin-bottom: 0 !important;">Pefis</h2>
                        <p>{{"login.message" | translate}}</p>
                    </div>

                    <div style="order:0;">
                        <a class="btn-flat waves-effect waves-light" style="order: 2; color: white; border-radius: 5px; border: 1px solid white !important; margin-bottom: 8rem;" routerLink="/about">{{ "buttons.about" | translate }}</a>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="col s12 l5">
        <!-- Login form  -->
        <div class="container cont-login">
            <div class="login">

                <div class="row">
                    <img src="assets/logo/icon.png" class="animated fadeIn" style="width: 15%; display:block; margin:auto;" id="imagel">
                    <h5 class="center animated fadeIn" style="color: #00AEFF;">{{ "login.head" | translate }}</h5>
                </div>

                <form #loginForm=ngForm class="animated fadeIn">
                    <div class="animated fadeIn" style="display:block; margin:auto;">

                        <div class="row">
                            <div class="input-field col l10 offset-l1 s12">
                                <i class="material-icons prefix small">mail_outline</i>
                                <input autofocus id="email" type="email" class="validate form-control" name="email" [(ngModel)]="user.email" (click)="errorCredenciales=false">
                                <label class="active" for="email">{{ "login.email" | translate }}</label>
                            </div>
                        </div>

                        <div class="row">
                            <div class="input-field col l10 offset-l1 s12">
                                <i class="material-icons prefix small">lock_outline</i>
                                <input id="password" type="password" class="validate form-control" name="password" [(ngModel)]="user.password" (click)="errorCredenciales=false">
                                <label for="password">{{ "login.password" | translate }}</label>

                                <p class="right">
                                    <label>
                    <input type="checkbox" name="rememberEmail" [(ngModel)]="rememberEmail" />
                    <span>{{"login.rememberEmail" | translate}}</span>
                  </label>
                                </p>

                            </div>
                        </div>


                        <div class="row">
                            <button (click)="onLogin()" class="btn waves-effect waves-light center hoverable" style="background-color: #00AEFF; display:block; margin:auto; width: 250px; border-radius: 5px;" type="submit" name="action" [disabled]="loading">{{ "buttons.login" | translate }}
                <i class="material-icons right">arrow_forward</i></button>
                        </div>
                        <p class="red-text center animated fadeIn" *ngIf="errorCredenciales">{{ "login.error" | translate }}</p>
                    </div>
                </form>

                <div class="center animated fadeIn">
                    <p class="grey-text text-darken-2">{{ "login.acount" | translate }}</p>
                    <a [routerLink]="['/user/signup']" style="color: #00AEFF; font-weight: bold">{{ "login.signup" | translate }}</a> | <a target="_blank" href="https://play.google.com/store/apps/details?id=com.elesteam.pefis" style="color: #00AEFF; font-weight: bold">{{ "login.dapp" | translate }}</a>
                    <br><a id="about" style="color: #00AEFF; font-weight: bold" routerLink="/about">{{ "buttons.about" | translate }}</a>
                </div>
                <br><br>
                <div>
                </div>
            </div>
        </div>
    </div>
</div>
