import { NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { BankComponent } from './bank/bank.component';
import { AhorroComponent } from './ahorro/ahorro.component';
import { DeudaComponent } from './deuda/deuda.component';
import { PrestamoComponent } from './prestamo/prestamo.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';


const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'bank', component: BankComponent, canActivate: [AuthGuard]},
  { path: 'ahorros', component: AhorroComponent, canActivate: [AuthGuard]},
  { path: 'deudas', component: DeudaComponent, canActivate: [AuthGuard]},
  { path: 'prestamos', component: PrestamoComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [ RouterModule.forChild(routes)],
  exports: [ RouterModule]
})
export class PagesRoutingModule { }
