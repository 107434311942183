import { Component, OnInit } from '@angular/core';
import { AcountService } from 'src/app/services/acount.service';
import { ToastrService } from 'ngx-toastr';
import { Transaction } from 'src/app/models/transaction';
import Swal from 'sweetalert2';
import { timer } from 'rxjs';
declare var $: any;
import { es, en } from '../../../assets/i18n/component-translate';


@Component({
  selector: 'app-prestamo',
  templateUrl: './prestamo.component.html',
  styleUrls: ['./prestamo.component.css']
})
export class PrestamoComponent implements OnInit {
  transactions: Array<any> = [];
  cards: Array<any> = [];
  total = null;
  totalPrestamo = null;
  email: string;
  cardSelected = new Transaction();
  allTransactions: Array<any> = [] ;
  values: Array<any> = [];
  allTotal = null;
  disabledAddTransaction = false;
  newLend = new Transaction();
  fecha: string;
  guardar = false;
  selectedOption: number;
  destinosTransfe: Array<any> = [];
  selectedDestino: string;
  cardSelectedValue = 0;
  loader = true;
  messages;
  timer$ = timer(1000);

  constructor(
    private dataService: AcountService,
    private toast: ToastrService
    ) {
      this.cardSelected.amount = 0;
      if (window.navigator.language.split('-')[0] == 'es') {
        this.messages = es;
      } else {
        this.messages = en;
      }
     }

  ngOnInit(): void {
    $(document).ready(() => {
      $('.modal').modal({
        dismissible: false
      });
    });

    this.getDataCards();
    this.email =  sessionStorage.getItem('email');
    this.getAllTransactions();
  }

  getDataCards() {
    this.dataService.getLend().subscribe(result => {
      this.cards = result;
      this.cardSelected = this.cards[0];
      this.loader = false;
      this.getTransactions(this.cardSelected);
      this.totalPrestamo = 0;
      this.cards.forEach(( element: any) => {
        this.totalPrestamo += element.amount;
      });
    }, error => console.log(error));
  }

  getTransactions(card) {
    this.cardSelected = null;
    this.transactions = null;
    this.cardSelected = card;
    if (this.cards.length > 0) {
    this.cardSelectedValue = this.cardSelected.amount;
    this.dataService.getLendPay(card.id).subscribe((result: any) => {
      this.transactions = result;
      this.calcularTotal();
    }, error => console.log(error));
  }

  }

  getAllTransactions() {
    this.dataService.getLend().subscribe((resp: any) => {
      this.allTotal = 0;
      for (const card of resp) {
        this.dataService.getLendPay(card.id).subscribe(response => {
          response.forEach((element: any) => {
            if (element.id){
              this.allTotal += element.amount;
            }
            // console.log(element);
          });
        });
      }
    });
  }

  calcularTotal() {
    this.total = 0;
    this.transactions.forEach(transaction => {
      this.total += transaction.amount;
    });

    this.disabledAddTransaction = false;
    if (this.total >= this.cardSelected.amount) {
      this.disabledAddTransaction = true;
      }

    this.timer$.subscribe(() => {
        this.getAllTransactions();
      });
  }

  onDeleteLend(transaccion: Transaction) {
    Swal.fire({
      title: `${this.messages.shure}`,
      text: `${this.messages.deleteIt} ${transaccion.description}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00AEFF',
      cancelButtonColor: '#bdbdbd',
      confirmButtonText: `${this.messages.delete}`,
      cancelButtonText: `${this.messages.cancel}`
  }).then((result) => {

      if (result.value) {
        this.dataService.deleteLend(transaccion.id)
        .then( () => {
          this.toast.success(`${transaccion.description} ${this.messages.deleted} `);
          this.dataService.deleteLendPays(transaccion.id)
        .then(() => {
          this.toast.success(`${this.messages.deletedTransactions}`);
        });
        }).catch( err => {
          this.toast.error('Error');
        });
        this.timer$.subscribe(() => {
          this.getAllTransactions();
        });
      }
    });
  }

  onDeleteLendPay(transaccion: Transaction) {

    Swal.fire({
      title: `${this.messages.shure}`,
      text: `${this.messages.deleteIt} ${transaccion.description}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00AEFF',
      cancelButtonColor: '#bdbdbd',
      confirmButtonText: `${this.messages.delete}`,
      cancelButtonText: `${this.messages.cancel}`
  }).then((result) => {

      if (result.value) {
        this.dataService.deleteLendPay(this.cardSelected.id, transaccion.id)
        .then(() => {
          this.toast.success(`${transaccion.description} ${this.messages.deleted} `);
        }).catch( err => {
          this.toast.error('Error');
        });

        this.timer$.subscribe(() => {
          this.getAllTransactions();
        });
      }
  });
  }

  onSaveLend() {
    if (this.newLend.amount < 0) {
      this.toast.error(`${this.messages.info}`);
    } else {

      const date = new Date(`${this.fecha}`);

      this.newLend.date_timestamp =  date;
      this.newLend.date =  `${this.fecha.split('-')[2]}/${this.fecha.split('-')[1]}/${this.fecha.split('-')[0]}`;

      this.dataService.addLend(this.newLend)
      .then( docRef => {
        this.toast.success(`${this.newLend.description} ${this.messages.added}`);
        this.eliminarDatos();
        docRef.update({
          'id': docRef.id
        });
      })
      .catch(err => {
        this.toast.error('Error');
        console.log(err);
      });

      if (this.guardar) {

        let tranferencia: Transaction;
        tranferencia = Object.assign({}, this.newLend);
        tranferencia.amount *= -1 ;

        if (this.selectedOption == 1) {
          tranferencia.description = tranferencia.description +  `${this.messages.fromLend}`;
          this.dataService.addBankPay(this.selectedDestino, tranferencia)
            .then(docRef => {
              this.toast.success(`${tranferencia.description} ${this.messages.added}`);
              this.eliminarDatos();
              docRef.update({
                'id': docRef.id
              });
            })
            .catch( err => {
              this.toast.error('Error');
              console.log(err);
            });
        }

        if (this.selectedOption == 2) {
          tranferencia.description = tranferencia.description + `${this.messages.fromLend}`;
          this.dataService.addSavingPay(this.selectedDestino, tranferencia)
            .then(docRef => {
              this.toast.success(`${tranferencia.description} ${this.messages.added}`);
              this.eliminarDatos();
              docRef.update({
                'id': docRef.id
              });
            })
            .catch( err => {
              this.toast.error('Error');
              console.log(err);
            });
        }

        if (this.selectedOption == 3) {
          tranferencia.description = tranferencia.description + `${this.messages.fromLend}`;
          this.dataService.addWalletTransaction(tranferencia)
            .then(docRef => {
              this.toast.success(`${tranferencia.description} ${this.messages.added}`);
              this.eliminarDatos();
              docRef.update({
                'id': docRef.id
              });
            })
            .catch( err => {
              this.toast.error('Error');
              console.log(err);
            });
        }
      }
    }
  }

  cancel() {
    this.eliminarDatos();
  }

  eliminarDatos() {
    this.newLend.amount = null;
    this.newLend.description = '';
    this.fecha = null;
    this.selectedDestino = null;
    this.selectedOption = null;
    this.guardar = false;
  }

  getSelectData(id) {
    this.destinosTransfe = [];
    if (id == 1) {
      this.dataService.getBank().subscribe(resp => {
        this.destinosTransfe = resp;
      });
    }

    if (id == 2) {
      this.dataService.getSaving().subscribe( resp => {
        this.destinosTransfe = resp;
      });
    }
  }

}
