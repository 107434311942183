export class User {

  email: string;
  password: string;
  name: string;
  surname: string;
  uid: string;

}

export interface UserInterfase {

  email?: string;
  password?: string;
  name?: string;
  surname?: string;
  uid?: string;

}

