<nav class="fixed animated fadIn">
  <div class="logo">
    <img src="assets/img/logo_full.png" style="height: 40px; margin-left: 15px; margin-top: 5px; " alt="">
  </div>

  <div class="buttons">
    <a class="btn-flat waves-effect waves-light right botton" style="color: #00AEFF; border-radius: 5px; display:block;"
      routerLink="/about">{{ "buttons.about" | translate }}</a>
    <a class="btn-flat waves-effect waves-light right botton"
      style="color: #00AEFF; border-radius: 5px; display:block; font-weight: bold"
      routerLink="/user/login">{{ "buttons.login" | translate }}</a>


  </div>
</nav>
<div class="contenedor">

  <div class="container">

    <div class="col s12 l12">

      <div class="buttonsr">
        <a class="btn-flat waves-effect waves-light right botton"
          style="color: #00AEFF; border-radius: 5px; display:block;"
          routerLink="/about">{{ "buttons.about" | translate }}</a>

        <a class="btn-flat waves-effect waves-light right hoverable button" style="color: #00AEFF;"
          routerLink="/user/login">{{ "buttons.login" | translate }}</a>
      </div>
      <br>
      <h3 style="color:  #00AEFF; padding-top: 2rem;" class="animated bounceInDown">{{"singnUp.head" | translate}}</h3>
      <p class="animated bounceInUp">* {{"singnUp.required" | translate}}</p>

      <!-- sign-Up form -->
      <form #userForm=ngForm class="animated fadeIn">
        <br><br>
        <div class="row">
          <div class="input-field col s12 l6">
            <input autofocus required="true" id="name" type="text" class="validate form-control" name="name"
              [(ngModel)]="user.name" (click)="errorDatos=false">
            <label for="name">{{"singnUp.name" | translate}} *</label>
          </div>

          <div class="input-field col l6 s12">
            <input required="true" id="surname" type="text" class="validate form-control" name="surname"
              [(ngModel)]="user.surname" (click)="errorDatos=false">
            <label for="surname">{{"singnUp.lastName" | translate}} *</label>
          </div>

          <div class="input-field col l6 s12">
            <input required="true" id="email" type="email" class="validate form-control" name="email"
              [(ngModel)]="user.email" (click)="errorDatos=false">
            <label for="email">{{"singnUp.email" | translate}} *</label>
          </div>

          <div class="input-field col l6 s12">
            <input required="true" id="password" minlength="6" type="password" class="validate form-control"
              name="password" [(ngModel)]="user.password" (click)="errorDatos=false">
            <label for="password">{{"singnUp.password" | translate}} *</label>
          </div>
        </div>

        <div class="row">
          <div class="col l8 s12">
            <button id="save" (click)="onSave()" class="btn waves-effect waves-light left hoverable"
              style="background-color: #00AEFF; display:block;  border-radius: 5px;" type="submit" name="action"
              [disabled]="!userForm.valid || errorDatos || loading">{{"buttons.signup" | translate}}
              <i class="material-icons right">add</i></button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="row animated fadeIn cont hoverable" id="footer">
  <div class="container">
    <p class="white-text"><b>Pefis</b> | {{fecha}}</p>
  </div>
</div>
