export const es  = {
  'shure': '¿Está seguro?',
  'deleteIt': 'Se eliminará',
  'delete': 'Si, eliminar',
  'cancel': 'Cancelar',
  'deleted': 'se eliminó',
  'deletedTransactions': 'Transacciones eliminadas',
  'added': 'se agregó correctamente',
  'fromLend': ' (De prestamos)',
  'info': 'La cantidad debe ser mayor a 0',
  'error': 'Error en los datos ingresados',
  'infob': 'La cantidad debe ser menor al monto de la deuda',
  'from': 'de',
  'infol': 'La cantidad debe ser menor al monto del prestamo',
  'transferBank': ' (tranferido a bancos)',
  'transferSaving': ' (tranferido a ahorros)',
  'transferWallet': ' (tranferido a cartera)',
  'fromWallet': ' (De cartera)',
};

export const en  = {
  'shure': 'Are you sure?',
  'deleteIt': 'It will be eliminated',
  'delete': 'Yes, delete',
  'cancel': 'Cancel',
  'deleted': 'it was deleted',
  'deletedTransactions': 'Deleted transactions',
  'added': 'added successfully',
  'fromLend': ' (From lends)',
  'info': 'Quantity must be greater than 0',
  'error': 'Error in the entered data',
  'infob': 'The amount must be less than the borrow amount',
  'from': 'from',
  'infol': 'The amount must be less than the loan amount',
  'transferBank': ' (transferred to banks)',
  'transferSaving': ' (transferred to savings)',
  'transferWallet': ' (transferred to wallet)',
  'fromWallet': ' (from wallet)',
};
