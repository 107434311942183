<div style="margin:0px;">
    <div id="hback">
        <div class="my_nav">
            <ul class="my_ul">
                <div class="ul_left">
                    <a class="as" [routerLink]="['/home']">
                        <li class="navs" id="config">
                            <img class="le-icons" src="assets/img/logo_title.png">
                        </li>
                    </a>
                </div>



                <div class="ul_right">
                    <a class="modal-trigger as" href="#meModal" id="auser_outer">
                        <li class="navs" id="config">
                            <p class="p_email" *ngIf="user">{{user.email}} </p>
                            <a class="modal-trigger" href="#meModal"> <img class="ri-icons" src="assets/img/userconfig.png"></a>
                        </li>
                    </a>
                </div>



                <div class="ul_midd">

                    <a class="as" [routerLink]="['/home']"  routerLinkActive =  "selectedUrl">
                        <li class="navs" id="config">
                            <img class="li-icons" src="assets/img/wallet.png">
                            <p class="li-label">{{ "finances.wallet" | translate }}</p>
                        </li>
                    </a>

                    <a class="as" [routerLink]="['/bank']"  routerLinkActive =  "selectedUrl">
                        <li class="navs" id="config">
                            <img class="li-icons" src="assets/img/bank.png">
                            <p class="li-label">{{ "finances.bank" | translate }}</p>
                        </li>
                    </a>

                    <a class="as" [routerLink]="['/ahorros']"  routerLinkActive =  "selectedUrl">
                        <li class="navs" id="config">
                            <img class="li-icons" src="assets/img/saving.png">
                            <p class="li-label">{{ "finances.saving" | translate }}</p>
                        </li>
                    </a>
                    <a class="as" [routerLink]="['/prestamos']"  routerLinkActive =  "selectedUrl">
                        <li class="navs" id="config">
                            <img class="li-icons" src="assets/img/lending.png">
                            <p class="li-label">{{ "finances.lend" | translate }}</p>
                        </li>
                    </a>
                    <a class="as" [routerLink]="['/deudas']"  routerLinkActive =  "selectedUrl">
                        <li class="navs" id="config">
                            <img class="li-icons" src="assets/img/borrowing.png">
                            <p class="li-label">{{ "finances.borrow" | translate }}</p>
                        </li>
                    </a>

                    <a class="as" href="#" id="auser_naved">
                        <li class="navs" id="config">
                            <img class="li-icons" src="assets/img/userconfig.png">
                            <p class="li-label">User</p>
                        </li>
                    </a>
                </div>
            </ul>
        </div>
    </div>
</div>


<!-- Modal user -->
<div id="meModal" class="modal bottom-sheet">
    <div class="modal-content" *ngIf="user">
        <h4>{{user.name }} {{user.surname}}</h4>
        <p>{{user.email}}</p>
        <br><br><br>
        <a style="background-color: #00AEFF; border-radius: 5px;" class="waves-effect waves-light btn modal-close" (click)="logOut()">{{ "buttons.logOut" | translate }}</a>
    </div>
    <div class="modal-footer">
        <a class="modal-close waves-effect waves-green btn-flat">OK</a>
    </div>
</div>
