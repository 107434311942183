<h4 style="padding-top: 20px;">{{ "finances.transferFrom" | translate }} {{ "finances.wallet" | translate }}</h4>
<span>* {{ "form.required" | translate }}</span><br><br>
<form #walletTransfeForm=ngForm class="animated fadeIn">

  <div  class="animated fadeIn" style="display:block; margin:auto;">

    <div class="row">
        <div class="input-field col l12 s12" >
          <i class="material-icons prefix small">short_text</i>
          <input autofocus required="true" id="descripcion" type="text" class="validate form-control" name="description" [(ngModel)]="transaccion.description" (click)="errorDatos=false" >
          <label for="descripcion">{{ "form.description" | translate }}  *</label>
        </div>
      </div>


    <div class="row">
      <div class="col  s12 l6">
        <div class="input-field">
          <i class="material-icons prefix small">monetization_on</i>
          <input required="true" id="cantidad" type="number" min="0" class="validate form-control" name="amount" [(ngModel)]="transaccion.amount"  (click)="errorDatos=false">
          <label for="cantidad">{{ "form.amount" | translate }} *</label>
        </div>
      </div>

      <div class="col s12 l6">
        <div class="input-field">
          <input type="date" id="date" name="date" [(ngModel)]="fecha" required="true">
          <label for="date">{{ "form.date" | translate }} *</label>
        </div>
      </div>
    </div>

    <p>{{ "messages.transferTo" | translate }}</p>
    <div class="row">

      <div class=" col s12 l6">
        <label>{{ "form.option" | translate }}</label>
        <select class="browser-default"  required [(ngModel)]="selectedOption" name="option" (ngModelChange)="getSelectData($event)" >
          <option value="0" disabled selected >{{ "form.option" | translate }}</option>
          <option value="1">{{ "finances.bank" | translate }}</option>
          <option value="2">{{ "finances.saving" | translate }}</option>
        </select>
      </div>

       <div class=" col s12 l6">
         <label>{{ "form.destination" | translate }}</label>
        <select required class="browser-default"  [(ngModel)]="selectedDestino" name="selectedDestino">
          <option value="0" disabled selected>{{ "form.destination" | translate }}</option>
          <option *ngFor="let item of destinosTransfe" [ngValue]="item.id">{{item.description}}</option>
        </select>

      </div>
    </div>



<div class="row">
  <button id="save" (click)="crearTanferencia()" class="modal-close btn waves-effect waves-light right hoverable" style="background-color: #00AEFF; display:block;  border-radius: 5px;" type="submit" name="action" [disabled]="!walletTransfeForm.valid || errorDatos" >{{ "buttons.save" | translate }}
    <i class="material-icons right">add</i></button>
    <a (click)="cancel()" class="modal-close waves-effect right waves-red btn-flat">{{ "buttons.cancel" | translate }}</a>
</div>
</div>
</form>
