import { Component, OnInit } from '@angular/core';
import { AcountService } from 'src/app/services/acount.service';
import { ToastrService } from 'ngx-toastr';
import { Transaction } from 'src/app/models/transaction';
import Swal from 'sweetalert2';
import { timer } from 'rxjs';
import { es, en } from '../../../assets/i18n/component-translate';


declare var $: any;

@Component({
  selector: 'app-ahorro',
  templateUrl: './ahorro.component.html',
  styleUrls: ['./ahorro.component.css']
})
export class AhorroComponent implements OnInit {

  transactions: any;
  cards: Array<any> = [];
  total = null;
  email: string;
  user: any;
  cardSelected: any;
  allTransactions: Array<any> = [] ;
  values : Array<any> = [];
  allTotal = null;
  newSaving = new Transaction();
  fecha: string;
  loader = true;
  messages;
  timer$ = timer(3000);

  constructor(
    private dataService: AcountService,
    private toast: ToastrService
    ) {

      if (window.navigator.language.split('-')[0] == 'es') {
        this.messages = es;
      } else {
        this.messages = en;
      }

     }


  ngOnInit(): void {
    $(document).ready(() => {
      $('.modal').modal({
        dismissible: false
      });
    });

    this.getDataCards();
    this.email =  sessionStorage.getItem('email');
    this.getUser();
    this.getAllTransactions();
  }

  getDataCards() {
    this.dataService.getSaving().subscribe(result => {
      this.cards = result;
      this.loader = false;
      this.cardSelected = this.cards[0];
      this.getTransactions(this.cardSelected);
    }, error => console.log(error));
  }

  getTransactions(card) {
    this.cardSelected = null;
    this.transactions = null;
    this.cardSelected = card;
    if (this.cards.length > 0) {
    this.dataService.getSavingPay(card.id).subscribe((result: any) => {
      this.transactions = result;
      this.calcularTotal();
    }, error => console.log(error));
  }
  }

  getAllTransactions() {
    let transactions = [];
    this.dataService.getSaving().subscribe((resp: any) => {
      for (const card of resp) {
        this.dataService.getSavingPay(card.id).subscribe(response => {
          response.forEach((element: any) => {
            transactions.push(element);
          });
        });
      }
    });

    this.timer$.subscribe(() => {
      this.allTotal = 0;
      let dataArr = new Set(transactions);
      let result = [...dataArr];
      result.forEach(t => {
        this.allTotal += t.amount;
      });
    });
  }

  calcularTotal() {
    this.allTotal = undefined;
    this.total = 0;
    this.transactions.forEach(transaction => {
      this.total += transaction.amount;
    });
    this.getAllTransactions();
  }

  getUser() {
    this.dataService.getUserData().subscribe(response => {
      this.user = response;
    } );
  }

  onDeleteSaving(transaccion: Transaction) {
    Swal.fire({
      title: `${this.messages.shure}`,
      text: `${this.messages.deleteIt} ${transaccion.description}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00AEFF',
      cancelButtonColor: '#bdbdbd',
      confirmButtonText: `${this.messages.delete}`,
      cancelButtonText: `${this.messages.cancel}`
  }).then((result) => {

      if (result.value) {
        this.dataService.deleteSaving(transaccion.id)
        .then( () => {
          this.toast.success(`${transaccion.description} ${this.messages.deleted} `);
          this.dataService.deleteSavingPays(transaccion.id)
        .then(() => {
          this.toast.success(`${this.messages.deletedTransactions}`);
        });
        }).catch( err => {
          this.toast.error('Error');
        });

        this.timer$.subscribe(() => {
          this.getAllTransactions();
        });
      }
    });
  }

  onDeleteSavingPay(transaccion: Transaction) {

    Swal.fire({
      title: `${this.messages.shure}`,
      text: `${this.messages.deleteIt} ${transaccion.description}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00AEFF',
      cancelButtonColor: '#bdbdbd',
      confirmButtonText: `${this.messages.delete}`,
      cancelButtonText: `${this.messages.cancel}`
  }).then((result) => {

      if (result.value) {
        this.dataService.deleteSavingPay(this.cardSelected.id, transaccion.id)
        .then(() => {
          this.toast.success(`${transaccion.description} ${this.messages.deleted} `);
        }).catch( err => {
          this.toast.error('Error');
        });

        this.timer$.subscribe(() => {
          this.getAllTransactions();
        });
      }
  });


  }

  onSaveSaving() {
    const date = new Date(`${this.fecha}`);

    this.newSaving.date_timestamp =  date;
    this.newSaving.date =  `${this.fecha.split('-')[2]}/${this.fecha.split('-')[1]}/${this.fecha.split('-')[0]}`;

    this.dataService.addSaving(this.newSaving)
    .then( docRef => {
      this.toast.success(`${this.newSaving.description} ${this.messages.added}`);
      this.eliminarDatos();
      docRef.update({
        'id': docRef.id
      });
    }).catch(err => {
      this.toast.error('Error');
      console.log(err);
    });
  }

  cancel() {
    this.eliminarDatos();
  }

  eliminarDatos() {
    this.newSaving.amount = null;
    this.newSaving.description = '';
    this.fecha = null;
  }
}

