
<div class="row">
  <div class="col s6 l6" [ngClass]="{'selectedt': transac}">
    <a href="#" onclick="return false;" (click)="clickTransac()"><h5 class="center modal-title">{{ "finances.transaction" | translate }}</h5></a>
  </div>
  <div class="col s6 l6" [ngClass]="{'selectedt': transfe}">
    <a href="#" onclick="return false;" (click)="clickTransfe()"><h5 class="center modal-title">{{ "finances.transfer" | translate }}</h5></a>
  </div>

</div>

<div class="container" *ngIf="saving" >
  <div class="row animated fadeIn" *ngIf="transac" >


      <h4 style="padding-top: 20px;">{{ "messages.addTo" | translate }} {{saving.description}}</h4>
      <span>* {{ "form.required" | translate }}</span><br><br>
      <form #walletForm=ngForm class="animated fadeIn">

        <div  class="animated fadeIn" style="display:block; margin:auto;">

          <div class="row">
              <div class="input-field col l12 s12" >
                <i class="material-icons prefix small">short_text</i>
                <input required="true" id="descripcion" type="text" class="validate form-control" name="description" [(ngModel)]="transaccion.description" (click)="errorDatos=false" >
                <label for="descripcion">{{ "form.description" | translate }} *</label>
              </div>
            </div>


          <div class="row">
            <div class="col  s12 l6">
              <div class="input-field">
                <i class="material-icons prefix small">monetization_on</i>
                <input required="true" id="cantidad" type="number" min="0" class="validate form-control" name="amount" [(ngModel)]="transaccion.amount"  (click)="errorDatos=false">
                <label for="cantidad">{{ "form.amount" | translate }} *</label>
              </div>
            </div>

            <div class="col s12 l6">
              <div class="input-field">
                <input type="date" id="date" name="date" [(ngModel)]="fecha" required="true">
                <label for="date">{{ "form.date" | translate }} *</label>
              </div>
            </div>
          </div>

            <div class="row">
                <div class="switch">
                  <label>
                    {{ "form.entry" | translate }}
                    <input type="checkbox" name="signo" [(ngModel)]='signo'>
                    <span class="lever"></span>
                    {{ "form.egress" | translate }}
                  </label>
                </div>

            </div>

      <div class="row">
        <button id="save" (click)="onSave()" class="modal-close btn waves-effect waves-light right hoverable" style="background-color: #00AEFF; display:block;  border-radius: 5px;" type="submit" name="action" [disabled]="!walletForm.valid || errorDatos" >{{ "buttons.save" | translate }}
          <i class="material-icons right">add</i></button>
          <a (click)="cancel()" class="modal-close waves-effect right waves-red btn-flat">{{ "buttons.cancel" | translate }}</a>
      </div>
      </div>
      </form>

  </div>
<!-- Agregar transferencia -->
  <div class="row animated fadeIn" *ngIf="transfe" >
    <app-saving-transferencia [ahorro]="saving"></app-saving-transferencia>

  </div>
</div>
