<app-nav-bar></app-nav-bar>
<app-loader *ngIf="!transactions"></app-loader>
<div class="container" *ngIf="transactions">
    <br>

    <div class="row animated fadeIn" *ngIf="loaded">
        <div class="col l6 s12">
            <div style="background-color: #00AEFF; border-radius: 12px; margin-bottom: 12px;">
                <div style="padding: 1.5rem">
                    <h6 *ngIf="user" style="color: white; font-family: 'gilroy-900';">{{user.name}} {{user.surname}}</h6>
                    <h3 style="margin-top: 0px; color: white; font-family: 'gilroy-100' !important;">{{total + totalBanco + totalahorros +(totalPrestamos - prestamos) + (totalDeudas - deudas) | currency}}</h3>

                </div>
                <div class="animated fadeIn informacion garf" *ngIf="transactions" style="border-radius: 12px 12px 0 0; background-color: white; padding: 1.5rem">
                    <span style="font-family: 'gilroy-900';">{{ "finances.summary" | translate }}</span><br><br>
                    <div>
                        <div>
                            <canvas style="min-height: 235px; max-height: 279px;" baseChart [datasets]="[{data:[total, totalBanco, totalahorros, totalPrestamos - prestamos, totalDeudas - deudas],  backgroundColor: 'rgba(0,174,255,0.2)', borderColor:'rgba(0,174,255,0.6)', pointBackgroundColor:'rgb(0,174,255)' }]"
                                [labels]="barChartLabels" [options]="barChartOptions" [legend]="barChartLegend" [chartType]="barChartType">
                        </canvas>
                        </div>
                    </div>
                </div>
            </div>

            <div class="slider-container topSlider">
                <swiper [breakpoints]="breakpoints" [navigation]="false" [autoplay]="true" [loop]="true">
                    <ng-template swiperSlide>
                        <div class="animated fadeIn ">
                            <div class="card-container">
                                <div class="card-content-f">
                                    <div class="circle-image" [ngClass]="{'colorB': totalBanco >= 0, 'colorC': totalBanco < 0}">
                                        <img src="assets/img/bank.svg" alt="" style="height: 16px;">
                                    </div>
                                    <div>
                                        <p class="card-header-money">{{ "finances.bank" | translate }}</p>
                                        <p class="card-money-value">{{totalBanco | currency}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="animated fadeIn">
                            <div class="card-container">
                                <div class="card-content-f">
                                    <div class="circle-image" [ngClass]="{'colorB': totalahorros >= 0, 'colorC': totalahorros < 0}">
                                        <img src="assets/img/saving.svg" alt="" style="height: 16px;">
                                    </div>
                                    <div>
                                        <p class="card-header-money">{{ "finances.saving" | translate }}</p>
                                        <p class="card-money-value">{{totalahorros | currency}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="animated fadeIn">
                            <div class="card-container">
                                <div class="card-content-f">
                                    <div class="circle-image" [ngClass]="{'colorB': (totalPrestamos - prestamos)>= 0, 'colorC': (totalPrestamos - prestamos) < 0}">
                                        <img src="assets/img/lending.svg" alt="" style="height: 16px;">
                                    </div>
                                    <div>
                                        <p class="card-header-money">{{ "finances.lend" | translate }}</p>
                                        <p class="card-money-value">{{(totalPrestamos - prestamos) | currency}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="animated fadeIn">
                            <div class="card-container">
                                <div class="card-content-f">
                                    <div class="circle-image" [ngClass]="{'colorB': (totalDeudas - deudas )>= 0, 'colorC': (totalDeudas - deudas) < 0}">
                                        <img src="assets/img/borrowing.svg" alt="" style="height: 16px;">
                                    </div>
                                    <div>
                                        <p class="card-header-money">{{ "finances.borrow" | translate }}</p>
                                        <p class="card-money-value">{{totalDeudas - deudas | currency}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </swiper>
            </div>
        </div>

        <div class="col l6 s12">
            <div class="card-home animated fadeIn" *ngIf="transactions.length == 0" style="border-radius: 5px;">
                <div class="">
                    <div class="" style="display: flex; align-items: center;">
                        <div style="display: flex; justify-content: center; align-items: center; height: 100px; width: 100px;">
                            <img src="assets/cardIcons/cartera.svg" width="60px" class="center">
                        </div>
                        <div class="">
                            <span style="font-family: 'gilroy-900';">{{ "messages.addTransaction" | translate }}</span><br>
                            <a class="waves-effect waves-light btn-small btn modal-trigger" href="#walletModal" style="background-color: #00AEFF; border-radius: 5px;"><i
                  class="material-icons right">add</i>{{ "buttons.add" | translate }}</a>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="col l6 s12">

            <div class="card-home animated fadeIn" *ngIf="transactions.length > 0" style="display: flex; justify-content: space-between;">
                <div style="display: flex; align-items: center;">
                    <h4 class="animated fadeIn" style="margin: 0;"> {{ "finances.wallet" | translate }} </h4>
                </div>
                <div>
                    <span class="right" style="margin-bottom: 0px;">Total</span><br>
                    <h4 class="right animated fadeIn" style="margin-top: 0px;" [ngClass]="{'green-text': total >= 0, 'red-text-custom': total < 0}">{{total | currency}} </h4>
                </div>

                <!-- <h6 *ngIf="transactions.length == 0">{{ "messages.noTransactions" | translate }}</h6> -->

            </div>


            <div class="card-detail" *ngIf="transactions.length > 0" style="height: 413px; margin-bottom: 0;">
                <div style="display: flex; justify-content: space-between; margin-top: 1rem; margin-bottom: 1rem;">
                    <div>
                        <h6 *ngIf="transactions.length > 0" style="font-family: 'gilroy-900';">{{ "finances.history" | translate }}</h6>
                    </div>
                    <div>
                        <a class="modal-trigger" href="#walletModal" style="background-color: #00AEFF; border-radius: 5px;"><i
                        class="material-icons right">add</i></a>
                        <!-- <a class="waves-effect waves-light btn-small btn modal-trigger" href="#walletModal" style="background-color: #00AEFF; border-radius: 5px;"><i
                        class="material-icons right">add</i>{{ "buttons.add" | translate }}</a> -->
                    </div>

                </div>
                <div style="overflow-y: auto; height: 300px;">
                    <table class="highlight">
                        <tbody>
                            <tr *ngFor="let transaction of transactions" style="margin: 0px !important;">
                                <td>
                                    <div style="display: flex; align-items: center;">
                                        <div class="circle-icons">
                                            <i class="material-icons green-text" *ngIf="transaction.amount >= 0">trending_up</i><i class="material-icons red-text-custom" *ngIf="transaction.amount < 0">trending_down</i>
                                        </div>
                                        <div>
                                            <h6 style="font-family: 'gilroy-900'; margin-bottom: 0;"> {{transaction.description}}</h6>
                                            <div style="display: flex;">
                                                <span class="text-muted" style="font-size: 10px;">{{transaction.date}}</span>&nbsp;&nbsp;
                                                <div *ngIf="transaction.t_from && transaction.t_to">
                                                    <img class="tranferencia-icon" src="assets/SVGIcons/{{transaction.t_from}}.svg">
                                                    <i class="material-icons" style="font-size: 15px;">keyboard_arrow_right</i>
                                                    <img class="tranferencia-icon" src="assets/SVGIcons/{{transaction.t_to}}.svg">
                                                </div>

                                            </div>
                                        </div>

                                    </div>


                                </td>

                                <td style="padding-right: 10px !important;">
                                    <!-- <h6 class="thin text right" [ngClass]="{'': transaction.amount >= 0, 'red-text-custom': transaction.amount < 0}"> -->
                                    <span class="right" style="font-size: 14px;">
                                        <a href="#" onclick="return false;"><i class="material-icons right red-text-custom"
                                      (click)="onDelete(transaction)" style="font-size: 20px;">delete</i></a>{{transaction.amount >= 0 ? '+':''}}{{transaction.amount | currency}} </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</div>

<!-- Wallet add -->
<div id="walletModal" class="modal">
    <div class="modal-content">
        <app-wallet-transaction></app-wallet-transaction>
    </div>
</div>
