import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { AboutComponent } from './auth/about/about.component';
import { AppComponent } from './app.component';
import { AuthRoutingModule } from './auth/auth.routing';
import { LoginComponent } from './auth/login-component/login-component.component';
import { PagesRoutingModule } from './pages/pages-routing.module';


const routes: Routes = [
  { path: '', redirectTo: '/about', pathMatch: 'full' },
  { path: '',
    component:  AppComponent,
    canActivate : [AuthGuard],
    loadChildren: () => import('./pages/pages-routing.module').then(m => m.PagesRoutingModule)
  },

  { path: '',
  component:  AppComponent,
  loadChildren: () => import('./auth/auth.routing').then(m => m.AuthRoutingModule)
  },

  { path: '**', redirectTo: 'user/login'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash: true}),
    AuthRoutingModule,
    PagesRoutingModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
