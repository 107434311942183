import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login-component/login-component.component';
import { SingUpComponent } from './sing-up/sing-up.component';
import { AboutComponent } from './about/about.component';

const routes: Routes = [
  { path: 'user/login', component: LoginComponent},
  { path: 'user/signup', component: SingUpComponent},
  { path: 'about', component: AboutComponent},

];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class AuthRoutingModule {}
