import { Component, OnInit } from '@angular/core';
declare var $: any;

// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {


   breakpoints = {
    300: {
      slidesPerView: 1.3,
      spaceBetween: 20
    },
    // when window width is >= 320px
    460: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    500: {
      slidesPerView: 2.3,
      spaceBetween: 20
    },
    // when window width is >= 480px
    900: {
      slidesPerView: 3.5,
      spaceBetween: 30
    },
    // when window width is >= 640px
    1200: {
      slidesPerView: 4.2,
      spaceBetween: 40
    }
  }

  constructor() {
    // this.calcularElementos();
  }


  ngOnInit(): void {
    $(document).ready(() => {
      $('.modal').modal({
        dismissible: false
      });
    });

  }

}
