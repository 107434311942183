import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Transaction } from '../models/transaction';


@Injectable({
  providedIn: 'root'
})
export class AcountService {

  constructor(
    private afe: AngularFirestore,
    ) {}

  get uid(): string {
    return localStorage.getItem('uid');
  }

  getUserData() {
    return this.afe.doc<any>(`user/${this.uid}`).valueChanges();
  }

  //cartera
  getWallet() {
    return  this.afe.collection<any>(`wallet/${this.uid}/wallets`, ref => ref.orderBy('date_timestamp', 'desc')).valueChanges();
  }

  addWalletTransaction( transaccion: Transaction) {
    let t;
    if (transaccion.t_from) {
       t = {
        'amount': transaccion.amount,
        'description': transaccion.description,
        'date': transaccion.date,
        'date_timestamp': transaccion.date_timestamp,
        't_from': transaccion.t_from,
        't_to': transaccion.t_to
      };
    } else {
       t = {
        'amount': transaccion.amount,
        'description': transaccion.description,
        'date': transaccion.date,
        'date_timestamp': transaccion.date_timestamp,
      };
    }
    return  this.afe.collection<Transaction>(`wallet/${this.uid}/wallets`).add(t);
  }

  deleteWalletTransaction(id: string) {
    return  this.afe.collection<Transaction>(`wallet/${this.uid}/wallets`).doc(id).delete();
  }

  //Banco
  getBank() {
    return this.afe.collection<any>(`bank/${this.uid}/banks`, ref => ref.orderBy('date_timestamp', 'desc')).valueChanges();
  }

  getBankPay( bankId: any ) {
    return this.afe.collection<any>(`bank-pay/${this.uid}/banks/${bankId}/pays`, ref => ref.orderBy('date_timestamp', 'desc')).valueChanges();
  }

  getBankPayt( bankId: any ) {
    return this.afe.collection<any>(`bank-pay/${this.uid}/banks/${bankId}/pays`).snapshotChanges();
  }


  addBank( bank: Transaction ) {

    return this.afe.collection<any>(`bank/${this.uid}/banks`).add(
      {
        'description': bank.description,
        'date': bank.date,
        'date_timestamp': bank.date_timestamp
      }
    );
  }

  addBankPay( bankId: string, transaccion: Transaction ) {
    let t;
    if (transaccion.t_from) {
       t = {
        'amount': transaccion.amount,
        'description': transaccion.description,
        'date': transaccion.date,
        'date_timestamp': transaccion.date_timestamp,
        't_from': transaccion.t_from,
        't_to': transaccion.t_to
      };
    } else {
       t = {
        'amount': transaccion.amount,
        'description': transaccion.description,
        'date': transaccion.date,
        'date_timestamp': transaccion.date_timestamp,
      };
    }

    return this.afe.collection<any>(`bank-pay/${this.uid}/banks/${bankId}/pays`).add(t);
  }

  deleteBank( bankId: string ) {
    return this.afe.collection<any>(`bank/${this.uid}/banks`).doc(bankId).delete();
  }

  deleteBankPay( bankId: string, payId: string ) {
    return this.afe.collection<any>(`bank-pay/${this.uid}/banks/${bankId}/pays`).doc(payId).delete();
  }

  deleteBankPays( bankId: string) {
    return this.afe.collection<any>(`bank-pay/${this.uid}/banks`).doc(bankId).delete();
  }


  //Ahorros
  getSaving() {
    return this.afe.collection<any>(`saving/${this.uid}/savings`, ref => ref.orderBy('date_timestamp', 'desc')).valueChanges();
  }

  getSavingPay(savingId: any) {
    return this.afe.collection<any>(`saving-pay/${this.uid}/savings/${savingId}/pays`,ref => ref.orderBy('date_timestamp', 'desc')).valueChanges();
  }

  addSaving( saving: Transaction ) {
    return this.afe.collection<any>(`saving/${this.uid}/savings`).add(
      {
        'description': saving.description,
        'date': saving.date,
        'date_timestamp': saving.date_timestamp
      }
    );
  }

  addSavingPay( savingId: string, transaccion: Transaction ) {
    let t;
    if (transaccion.t_from) {
       t = {
        'amount': transaccion.amount,
        'description': transaccion.description,
        'date': transaccion.date,
        'date_timestamp': transaccion.date_timestamp,
        't_from': transaccion.t_from,
        't_to': transaccion.t_to
      };
    } else {
       t = {
        'amount': transaccion.amount,
        'description': transaccion.description,
        'date': transaccion.date,
        'date_timestamp': transaccion.date_timestamp,
      };
    }

    return this.afe.collection<any>(`saving-pay/${this.uid}/savings/${savingId}/pays`).add(t);
  }

  deleteSaving( savingId: string ) {
    return this.afe.collection<any>(`saving/${this.uid}/savings`).doc(savingId).delete();
  }

  deleteSavingPay( savingId: string, payId: string) {
    return this.afe.collection<any>(`saving-pay/${this.uid}/savings/${savingId}/pays`).doc(payId).delete();
  }

  deleteSavingPays( savingId: string ) {
    return this.afe.collection<any>(`saving-pay/${this.uid}/savings`).doc(savingId).delete();
  }



  //deuda

  getBorrow() {
    return this.afe.collection<any>(`borrow/${this.uid}/borrows`, ref => ref.orderBy('date_timestamp', 'desc')).valueChanges();
  }

  getBorrowPay( borrowId ) {
    return this.afe.collection<any>(`borrow-pay/${this.uid}/borrows/${borrowId}/pays`, ref => ref.orderBy('date_timestamp', 'desc')).valueChanges();
  }

  addBorrow( borrow: Transaction ) {
    return this.afe.collection<any>(`borrow/${this.uid}/borrows`).add(
      {
        'amount': borrow.amount,
        'description': borrow.description,
        'date': borrow.date,
        'date_timestamp': borrow.date_timestamp
      }
    );
  }

  addBorrowPay( borrowId: string, borrowPay: Transaction ) {
    return this.afe.collection<any>(`borrow-pay/${this.uid}/borrows/${borrowId}/pays`).add(
      {
        'amount': borrowPay.amount,
        'description': borrowPay.description,
        'date': borrowPay.date,
        'date_timestamp': borrowPay.date_timestamp
      }
    );
  }

  deleteBorrow( borrowId: string ) {
    return this.afe.collection<any>(`borrow/${this.uid}/borrows`).doc(borrowId).delete();
  }

  deleteBorrowPay( borrowId: string, payId: string) {
    return this.afe.collection<any>(`borrow-pay/${this.uid}/borrows/${borrowId}/pays`).doc(payId).delete();
  }

  deleteBorrowPays(borrowId: string ) {
    return this.afe.collection<any>(`borrow-pay/${this.uid}/borrows`).doc(borrowId).delete();
  }



//Prestamo

  getLend() {
    return this.afe.collection<any>(`lend/${this.uid}/lends`, ref => ref.orderBy('date_timestamp', 'desc')).valueChanges();
  }

  getLendPay(lendId) {
    return this.afe.collection<any>(`lend-pay/${this.uid}/lends/${lendId}/pays`, ref => ref.orderBy('date_timestamp', 'desc')).valueChanges();
  }

  addLend( lend: Transaction ) {
    return this.afe.collection<any>(`lend/${this.uid}/lends`).add(
      {
        'amount': lend.amount,
        'description': lend.description,
        'date': lend.date,
        'date_timestamp': lend.date_timestamp
      }
    );
  }

  addLendPay( lendId: string, lendPay: Transaction) {
    return this.afe.collection<any>(`lend-pay/${this.uid}/lends/${lendId}/pays`).add(
      {
        'amount': lendPay.amount,
        'description': lendPay.description,
        'date': lendPay.date,
        'date_timestamp': lendPay.date_timestamp
      }
    );
  }

  deleteLend( lendId: string ) {
    return this.afe.collection<any>(`lend/${this.uid}/lends`).doc(lendId).delete();
  }

  deleteLendPay( lendId: string, payId: string) {
    return this.afe.collection<any>(`lend-pay/${this.uid}/lends/${lendId}/pays`).doc(payId).delete();
  }

  deleteLendPays( lendId: string) {
    return this.afe.collection<any>(`lend-pay/${this.uid}/lends`).doc(lendId).delete();
  }

}
