import { Component, OnInit } from '@angular/core';
import { AcountService } from 'src/app/services/acount.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth.service';


declare var $: any;

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css', './responsive.css', './style.css']
})
export class NavBarComponent implements OnInit {

  constructor(
    private dataService: AcountService,
    private router: Router,
    private auth: AuthService,
    private toast: ToastrService
    ) { }

  email: string;
  user: any;

  ngOnInit(): void {

    this.getUser();
  }

  getUser() {
    this.dataService.getUserData().subscribe(response => {
      this.user = response;
    } );
  }

  logOut() {
    this.auth.logoutUser();
    localStorage.removeItem('uid');
    this.router.navigateByUrl('user/login');

  }

}
