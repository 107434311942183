import { Component, OnInit, Input } from '@angular/core';
import { Transaction } from 'src/app/models/transaction';
import { ToastrService } from 'ngx-toastr';
import { AcountService } from 'src/app/services/acount.service';
import { es, en } from '../../../../assets/i18n/component-translate';


@Component({
  selector: 'app-borrow-transaction',
  templateUrl: './borrow-transaction.component.html',
  styleUrls: ['./borrow-transaction.component.css']
})
export class BorrowTransactionComponent implements OnInit {

  @Input() borrow: Transaction;
  @Input() pendiente: number;

  transaccion = new Transaction();
  errorDatos = false;
  fecha: string;
  guardar = false;
  selectedOption: number;
  destinosTransfe: Array<any> = [];
  selectedDestino: string;
  messages;

  constructor(
    private toast: ToastrService,
    private dataService: AcountService) {
      if (window.navigator.language.split('-')[0] == 'es') {
        this.messages = es;
      } else {
        this.messages = en;
      }
    }

    ngOnInit(): void {
      console.log(this.borrow);
    }

    onSave() {
      if (!this.transaccion.description || this.transaccion.amount < 0 || !this.fecha || this.transaccion.amount > this.pendiente) {
        this.errorDatos = true;
        if ( this.transaccion.amount > this.pendiente) {
          this.toast.error(`${this.messages.infob}`);
        }
        this.toast.error(`${this.messages.error}`);
      } else {


        const date = new Date(`${this.fecha}`);

        this.transaccion.date_timestamp =  date;
        this.transaccion.date = `${this.fecha.split('-')[2]}/${this.fecha.split('-')[1]}/${this.fecha.split('-')[0]}`;

        this.dataService.addBorrowPay(this.borrow.id, this.transaccion)
          .then(docRef => {
            this.toast.success(`${this.transaccion.description} ${this.messages.added}`);
            this.eliminarDatos();
            docRef.update({
              'id': docRef.id
            });
          }).catch( err => {
            this.toast.error('Error');
            console.log(err);
          });

        if (this.guardar) {

            let tranferencia: Transaction;
            tranferencia = Object.assign({}, this.transaccion);
            tranferencia.amount *= -1 ;

            if (this.selectedOption == 1) {
              tranferencia.description = tranferencia.description +  ` (${this.messages.from} ${this.borrow.description})`;
              this.dataService.addBankPay(this.selectedDestino, tranferencia)
                .then(docRef => {
                  this.toast.success(`${tranferencia.description} ${this.messages.added}`);
                  this.eliminarDatos();
                  docRef.update({
                    'id': docRef.id
                  });
                }).catch( err => {
                  this.toast.error('Error');
                  console.log(err);
                });
            }

            if (this.selectedOption == 2) {
              tranferencia.description = tranferencia.description + ` (${this.messages.from} ${this.borrow.description})`;
              this.dataService.addSavingPay(this.selectedDestino, tranferencia)
                .then(docRef => {
                  this.toast.success(`${tranferencia.description} ${this.messages.added}`);
                  this.eliminarDatos();
                  docRef.update({
                    'id': docRef.id
                  });
                }).catch( err => {
                  this.toast.error('Error');
                  console.log(err);
                });
            }

            if (this.selectedOption == 3) {
              tranferencia.description = tranferencia.description + ` (${this.messages.from} ${this.borrow.description})`;
              this.dataService.addWalletTransaction(tranferencia)
                .then(docRef => {
                  this.toast.success(`${tranferencia.description} ${this.messages.added}`);
                  this.eliminarDatos();
                  docRef.update({
                    'id': docRef.id
                  });
                }).catch( err => {
                  this.toast.error('Error');
                  console.log(err);
                });
            }

          }
      }
    }

    cancel() {
      this.eliminarDatos();
    }

    eliminarDatos() {
      this.transaccion.amount = null;
      this.transaccion.description = '';
      this.fecha = null;
      this.selectedDestino = null;
      this.selectedOption = null;
      this.guardar = false;
    }

    getSelectData(id) {
      this.destinosTransfe = [];
      if (id == 1) {
        this.dataService.getBank().subscribe(resp => {
          this.destinosTransfe = resp;
        });
      }

      if (id == 2) {
        this.dataService.getSaving().subscribe( resp => {
          this.destinosTransfe = resp;
        });
      }
    }

}
