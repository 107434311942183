<app-nav-bar></app-nav-bar>
<app-loader *ngIf="loader"></app-loader>
<div class="container" *ngIf="!loader">
    <div class="row">
        <div class="col s12 l6">
            <h1 class="Black text left animated fadeIn">{{ "finances.saving" | translate }}</h1>
        </div>

        <div class="col s12 l6">
            <br><br>
            <span class="right" style="margin-bottom: 0px;">Total</span><br>
            <h4 *ngIf="allTotal" class="right animated fadeIn" style="margin-top: 0px;" [ngClass]="{'green-text': allTotal >= 0, 'red-text-custom': allTotal < 0}">{{allTotal | currency}} </h4>
            <img *ngIf="!allTotal" class="right animated fadeIn" src="./assets/img/loader.svg" alt="" style="height: 25px;">
        </div>
    </div>
</div>

<div class="container" *ngIf="!loader">
    <div class="row">
        <div class="col s12 l6">
            <a class="waves-effect waves-light btn-small btn modal-trigger" href="#ahorroModal" style="background-color: #00AEFF; border-radius: 5px;"><i
          class="material-icons right">add</i>{{ "messages.addSaving" | translate }}</a><br>
        </div>

    </div>
    <div class="row">

        <div class="col l6 s12" *ngIf="cards.length == 0 && !loader">
            <div class="card-home animated fadeIn" style="border-radius: 5px; margin-left: 0 !important;">
                <div class="">
                    <div class="" style="display: flex; align-items: center;">
                        <div style="display: flex; justify-content: center; align-items: center; height: 100px; width: 100px;">
                            <img src="assets/cardIcons/ahorro.svg" width="60px" class="center">
                        </div>
                        <div class="">
                            <span style="font-family: 'gilroy-900';">{{ "messages.addSaving" | translate }}</span><br>
                            <span>{{ "messages.click" | translate }}</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>


        <div class="col s12 l4 animated fadeIn" *ngFor="let card of cards">
            <div class="card hoverable" style="border-radius: 5px;" (click)="getTransactions(card)" [ngClass]="{'selected animated bounce': card.id === cardSelected.id}">
                <div class="card-content">
                    <span class="card-title">{{card.description| titlecase}}</span><br><br><br>
                    <span style="font-size: 10px;">{{card.date}}</span>
                    <a href="#" onclick="return false;" (click)="onDeleteSaving(card)"><span class="material-icons right"
              [ngClass]="{'white-text': card.id === cardSelected.id, 'red-text-custom': card.id != cardSelected.id }" style="font-size: 20px;" >delete</span></a>


                </div>
            </div>
        </div>


    </div>

    <div class="card-detail animated fadeIn" *ngIf="transactions && cards.length > 0" style="border-radius: 5px;">


        <h5 *ngIf="cardSelected" style="font-family: 'gilroy-900';">{{cardSelected.description| titlecase}}</h5>

        <span *ngIf="transactions.length > 0" class="right" style="margin-bottom: 0px;">Total</span><br>
        <h5 class="right animated fadeIn" style="margin-top: 0px;" *ngIf="total" [ngClass]="{'green-text': total >= 0, 'red-text-custom': total < 0}">{{total | currency}} </h5>

        <h6 *ngIf="transactions.length == 0">{{ "messages.noTransactions" | translate }}</h6>
        <a class="waves-effect waves-light btn-small btn modal-trigger" href="#savingTransactiontModal" style="background-color: #00AEFF; border-radius: 5px;"><i
        class="material-icons right">add</i>{{ "buttons.add" | translate }}</a>

        <div style="padding-top: 10px;">
            <h6 *ngIf="transactions.length > 0">{{ "finances.history" | translate }}</h6>
        </div>
        <table class="highlight">
            <tbody>
                <tr *ngFor="let transaction of transactions" style="margin: 0px !important;">
                    <td>
                        <div style="display: flex; align-items: center;">
                            <div class="circle-icons">
                                <i class="material-icons green-text" *ngIf="transaction.amount >= 0">trending_up</i><i class="material-icons red-text-custom" *ngIf="transaction.amount < 0">trending_down</i>
                            </div>
                            <div>
                                <h6 style="font-family: 'gilroy-900'; margin-bottom: 0;"> {{transaction.description}}</h6>
                                <div style="display: flex;">
                                    <span class="text-muted" style="font-size: 10px;">{{transaction.date}}</span>&nbsp;&nbsp;
                                    <div *ngIf="transaction.t_from && transaction.t_to">
                                        <img class="tranferencia-icon" src="assets/SVGIcons/{{transaction.t_from}}.svg">
                                        <i class="material-icons" style="font-size: 15px;">keyboard_arrow_right</i>
                                        <img class="tranferencia-icon" src="assets/SVGIcons/{{transaction.t_to}}.svg">
                                    </div>

                                </div>
                            </div>
                        </div>
                    </td>

                    <td style="padding-right: 10px !important;">
                        <span class="right" style="font-size: 14px;">
                        <a href="#" onclick="return false;"><i class="material-icons right red-text-custom"
                      (click)="onDeleteSavingPay(transaction)" style="font-size: 20px;">delete</i></a>{{transaction.amount >= 0 ? '+':''}}{{transaction.amount | currency}} </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- Agregar saving -->
<div id="ahorroModal" class="modal">
    <div class="modal-content">
        <div class="container">
            <div class="row animated fadeIn">

                <h4 style="padding-top: 20px;">{{ "messages.addSaving" | translate }}</h4>
                <span>* {{ "form.required" | translate }}</span><br><br>
                <form #savingForm=ngForm class="animated fadeIn">

                    <div class="animated fadeIn" style="display:block; margin:auto;">

                        <div class="row">
                            <div class="input-field col l12 s12">
                                <i class="material-icons prefix small">short_text</i>
                                <input required="true" id="descripcion" type="text" class="validate form-control" name="description" [(ngModel)]="newSaving.description">
                                <label for="descripcion">{{ "form.description" | translate }} *</label>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col s12 l12">
                                <div class="input-field">
                                    <input type="date" id="date" name="date" [(ngModel)]="fecha" required="true">
                                    <label for="date">{{ "form.date" | translate }} *</label>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <button (click)="onSaveSaving()" class="btn waves-effect waves-light right hoverable modal-close" style="background-color: #00AEFF; display:block;  border-radius: 5px;" type="submit" name="action" [disabled]="!savingForm.valid">{{ "buttons.save" | translate }}
                <i class="material-icons right">add</i></button>
                            <a (click)="cancel()" class="modal-close waves-effect right waves-red btn-flat">{{ "buttons.cancel" | translate }}</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Agregar savign pay modal -->
<div id="savingTransactiontModal" class="modal">
    <div class="modal-content" *ngIf="cards">
        <app-saving-transaction [saving]="cardSelected"></app-saving-transaction>
    </div>
</div>
