<nav class="fixed animated fadIn">
    <div class="logo">
        <img src="assets/img/logo_full.png" style="height: 40px; margin-left: 15px; margin-top: 5px;" alt="">
    </div>

    <div class="buttons">


        <a class="btn-flat waves-effect waves-light right hoverable button" style="color: #00AEFF;" routerLink="/user/signup">{{ "login.signup" | translate }}</a>

        <a class="btn-flat waves-effect waves-light right hoverable button" style="color: #00AEFF; font-weight: bold" routerLink="/user/login">{{ "buttons.login" | translate }}</a>

    </div>
</nav>

<div class="row info">
    <div class="col s12 l12 animated fadeIn cont">
        <div>
            <div class="container animated fadeIn headcontent" style="height: 100vh;">
                <h1 class="white-text animated fadeIn">{{"about.welcome" | translate}}</h1>
                <h6 class="white-text">{{ "login.info" | translate }}</h6>

                <div class="buttonsr">
                    <a class="btn-flat waves-effect waves-light right hoverable button" style="color: white;  border: 1px solid white !important;" routerLink="/user/signup">{{ "login.signup" |
            translate }}</a>

                    <a class="btn-flat waves-effect waves-light right hoverable button" style="color: white;  border: 1px solid white !important;" routerLink="/user/login">{{ "buttons.login" |
            translate }}</a>
                </div>
            </div>


        </div>
    </div>
</div>



<div class="row contenedor container">

    <div class="headcontent">
        <div class="">
            <h4 class="center thin animated bounceInUp">{{"about.features" | translate}}</h4><br>
        </div>
    </div>

    <swiper class="swiper-container animated fadeIn" [breakpoints]="breakpoints" [autoplay]="true">
        <ng-template swiperSlide>
            <div class="card" style="margin-left: 2px;">
                <div class="card-content">
                    <img class="image" src="assets/img/wallet.png">
                    <h6 style="font-family: 'gilroy-900';"><b>{{ "finances.wallet" | translate }}</b></h6>
                    <p>{{"about.wallet" | translate}}</p>
                </div>
            </div>
        </ng-template>
        <ng-template swiperSlide>
            <div class="card">
                <div class="card-content">
                    <img class="image" src="assets/img/bank.png">
                    <h6 style="font-family: 'gilroy-900';"><b>{{ "finances.bank" | translate }}</b></h6>
                    <p>{{"about.bank" | translate}}</p>
                </div>
            </div>
        </ng-template>
        <ng-template swiperSlide>
            <div class="card">
                <div class="card-content">
                    <img class="image" src="assets/img/saving.png">
                    <h6 style="font-family: 'gilroy-900';"><b>{{ "finances.saving" | translate }}</b></h6>
                    <p>{{"about.saving" | translate}}</p>
                </div>
            </div>
        </ng-template>
        <ng-template swiperSlide>
            <div class="card">
                <div class="card-content">
                    <img class="image" src="assets/img/lending.png">
                    <h6 style="font-family: 'gilroy-900';"><b>{{ "finances.lend" | translate }}</b></h6>
                    <p>{{"about.lend" | translate}}</p>
                </div>
            </div>
        </ng-template>
        <ng-template swiperSlide>
            <div class="card">
                <div class="card-content">
                    <img class="image" src="assets/img/borrowing.png">
                    <h6 style="font-family: 'gilroy-900';"><b>{{ "finances.borrow" | translate }}</b></h6>
                    <p>{{"about.borrow" | translate}}</p>
                </div>
            </div>
        </ng-template>

    </swiper>
</div>



<div class="row animated bounceInUp contb hoverable">
    <div class="container">
        <h3 class="white-text tittle">{{"about.tools" | translate}}</h3>
        <div class="row animated fadeIn" style="padding-top: 50px; padding-bottom: 50px;">

            <div class="col s4">
                <img class="logo" src="assets/img/angular.png">
                <h6 class="white-text">Angular 10</h6>
            </div>
            <div class="col s4">
                <img class="logo" src="assets/img/firebase.png">
                <h6 class="white-text">Cloud Firestore</h6>
            </div>
            <div class="col s4">
                <img class="logo" src="assets/img/materialize.png">
                <h6 class="white-text">Materialize CSS</h6>
            </div>

        </div>

    </div>
</div>
