import { Component, OnInit, Input } from '@angular/core';
import { Transaction } from 'src/app/models/transaction';
import { ToastrService } from 'ngx-toastr';
import { AcountService } from 'src/app/services/acount.service';
import { es, en } from '../../../../assets/i18n/component-translate';


@Component({
  selector: 'app-bank-transferencia',
  templateUrl: './bank-transferencia.component.html',
  styleUrls: ['./bank-transferencia.component.css']
})
export class BankTransferenciaComponent implements OnInit {

  @Input() banco: Transaction;

  transaccion = new Transaction();
  errorDatos = false;
  fecha: string;
  selectedOption: number;
  destinosTransfe: Array<any> = [];
  selectedDestino: string;
  closeModal = false;
  messages;

  constructor(
    private toast: ToastrService,
    private dataService: AcountService
  ) {
    if (window.navigator.language.split('-')[0] == 'es') {
      this.messages = es;
    } else {
      this.messages = en;
    }
  }

  ngOnInit(): void {
  }

  onSaveBankPay() {
    this.transaccion.t_from = 'bank';
    this.transaccion.amount *= -1;
    if (this.selectedOption == 1) {this.transaccion.t_to = 'bank';}

    if (this.selectedOption == 2) {this.transaccion.t_to = 'saving';}

    if (this.selectedOption == 3) {this.transaccion.t_to = 'wallet';}

    this.dataService.addBankPay(this.banco.id, this.transaccion)
      .then(docRef => {
        this.toast.success(`${this.transaccion.description} ${this.messages.added}`);
        this.eliminarDatos();
        docRef.update({
          'id': docRef.id
        });
      }).catch(err => {
        this.toast.error('Error');
        console.log(err);
      });
  }

  cancel() {
    this.eliminarDatos();
  }

  eliminarDatos() {
    this.transaccion.amount = null;
    this.transaccion.description = '';
    this.fecha = null;
    this.selectedDestino = null;
    this.selectedOption = null;
  }

  getSelectData(id) {
    this.destinosTransfe = [];
    if (id == 1) {
      this.dataService.getBank().subscribe(resp => {

        this.destinosTransfe = resp.filter((t: Transaction) => t.id !== this.banco.id);
      });
    }

    if (id == 2) {
      this.dataService.getSaving().subscribe(resp => {
        this.destinosTransfe = resp;
      });
    }

  }

  crearTanferencia() {

    let tranferencia: Transaction;

    if (!this.transaccion.description || this.transaccion.amount < 0 || !this.fecha) {
      this.errorDatos = true;
      this.toast.error(`${this.messages.error}`);
    } else {

      const date = new Date(`${this.fecha}`);

      this.transaccion.date_timestamp = date;
      this.transaccion.date = `${this.fecha.split('-')[2]}/${this.fecha.split('-')[1]}/${this.fecha.split('-')[0]}`;
      tranferencia = Object.assign({}, this.transaccion);
      tranferencia.t_from = 'bank';

      if (this.selectedOption == 1) {
        tranferencia.t_to = 'bank';
        this.dataService.addBankPay(this.selectedDestino, tranferencia)
          .then(docRef => {
            this.toast.success(`${tranferencia.description} ${this.messages.added}`);
            this.onSaveBankPay();
            this.eliminarDatos();
            docRef.update({
              'id': docRef.id
            });
          }).catch(err => {
            this.toast.error('Error');
            console.log(err);
          });
      }

      if (this.selectedOption == 2) {
        tranferencia.t_to = 'saving';
        this.dataService.addSavingPay(this.selectedDestino, tranferencia)
          .then(docRef => {
            this.toast.success(`${tranferencia.description} ${this.messages.added}`);
            this.onSaveBankPay();
            this.eliminarDatos();
            docRef.update({
              'id': docRef.id
            });
          }).catch(err => {
            this.toast.error('Error');
            console.log(err);
          });
      }

      if (this.selectedOption == 3) {
        tranferencia.t_to = 'wallet';
        this.dataService.addWalletTransaction(tranferencia)
          .then(docRef => {
            this.toast.success(`${tranferencia.description} ${this.messages.added}`);
            this.onSaveBankPay();
            this.eliminarDatos();
            docRef.update({
              'id': docRef.id
            });
          }).catch(err => {
            this.toast.error('Error');
            console.log(err);
          });
      }
    }


  }

}

