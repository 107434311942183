import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { BankComponent } from './bank/bank.component';
import { AhorroComponent } from './ahorro/ahorro.component';
import { PrestamoComponent } from './prestamo/prestamo.component';
import { DeudaComponent } from './deuda/deuda.component';
import { WalletTransactionComponent } from './transaction-component/wallet-transaction/wallet-transaction.component';
import { BankTransactionComponent } from './transaction-component/bank-transaction/bank-transaction.component';
import { SavingTransactionComponent } from './transaction-component/saving-transaction/saving-transaction.component';
import { BorrowTransactionComponent } from './transaction-component/borrow-transaction/borrow-transaction.component';
import { LendTransactionComponent } from './transaction-component/lend-transaction/lend-transaction.component';
import { WalletTransferenciaComponent } from './transferencias-component/wallet-transferencia/wallet-transferencia.component';
import { BankTransferenciaComponent } from './transferencias-component/bank-transferencia/bank-transferencia.component';
import { SavingTransferenciaComponent } from './transferencias-component/saving-transferencia/saving-transferencia.component';

//Transalate Modules
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RouterModule } from '@angular/router';
import { SwiperModule } from 'swiper/angular';

//Loader de idiomas
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}



@NgModule({
  declarations: [
    HomeComponent,
    BankComponent,
    AhorroComponent,
    PrestamoComponent,
    DeudaComponent,
    WalletTransactionComponent,
    BankTransactionComponent,
    SavingTransactionComponent,
    BorrowTransactionComponent,
    LendTransactionComponent,
    WalletTransferenciaComponent,
    BankTransferenciaComponent,
    SavingTransferenciaComponent,
  ],
  exports: [
    HomeComponent,
    BankComponent,
    AhorroComponent,
    PrestamoComponent,
    DeudaComponent,
    WalletTransactionComponent,
    BankTransactionComponent,
    SavingTransactionComponent,
    BorrowTransactionComponent,
    LendTransactionComponent,
    WalletTransferenciaComponent,
    BankTransferenciaComponent,
    SavingTransferenciaComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    ChartsModule,
    BrowserAnimationsModule,
    RouterModule,
    SwiperModule,

  ]
})
export class PagesModule { }
