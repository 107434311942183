import { Component, OnInit } from '@angular/core';
import { AcountService } from 'src/app/services/acount.service';
import { ToastrService } from 'ngx-toastr';
import { Transaction } from 'src/app/models/transaction';
import Swal from 'sweetalert2';
import { timer } from 'rxjs';
import { es, en } from '../../../assets/i18n/component-translate';


declare var $: any;

@Component({
  selector: 'app-deuda',
  templateUrl: './deuda.component.html',
  styleUrls: ['./deuda.component.css']
})
export class DeudaComponent implements OnInit {

  transactions: any;
  cards: Array<any> = [];
  total = null;
  email: string;
  cardSelected = new Transaction();
  allTransactions: Array<any> = [] ;
  values: Array<any> = [];
  allTotal = null;
  totalDeuda = null;
  disabledAddTransaction = false;
  newBorrow = new Transaction();
  fecha: string;
  cardSelectedValue = 0;
  loader = true;
  messages;
  timer$ = timer(1000);


  constructor(
    private dataService: AcountService,
    private toast: ToastrService
    ) {
      if (window.navigator.language.split('-')[0] == 'es') {
        this.messages = es;
      } else {
        this.messages = en;
      }
     }


  ngOnInit(): void {
    $(document).ready(() => {
      $('.modal').modal({
        dismissible: false
      });
    });

    this.getDataCards();
    this.email =  sessionStorage.getItem('email');
    this.getAllTransactions();
  }

  getDataCards() {
    this.dataService.getBorrow().subscribe(result => {
      this.cards = result;
      this.loader = false;
      this.cardSelected = this.cards[0];
      this.getTransactions(this.cardSelected);
      this.totalDeuda = 0;
      this.cards.forEach(( element: any) => {
        this.totalDeuda += element.amount;
      });
    }, error => console.log(error));
  }

  getTransactions(card) {
    this.cardSelected = null;
    this.transactions = null;
    this.cardSelected = card;
    if (this.cards.length > 0) {
    this.cardSelectedValue = this.cardSelected.amount;
    this.dataService.getBorrowPay(card.id).subscribe((result: any) => {
      this.transactions = result;
      this.calcularTotal();
    }, error => console.log(error));
   }
  }

  getAllTransactions() {
    this.dataService.getBorrow().subscribe((resp: any) => {
      this.allTotal = 0;
      for (const card of resp) {
        this.dataService.getBorrowPay(card.id).subscribe(response => {
          response.forEach((element: any) => {
            if (element.id){
              this.allTotal += element.amount;
            }
          });
        });
      }
    });
  }

  calcularTotal() {
    this.total = 0;
    this.transactions.forEach(transaction => {
      this.total += transaction.amount;
    });
    this.disabledAddTransaction = false;
    if (this.total >= this.cardSelected.amount) {
      this.disabledAddTransaction = true;
      }
    this.timer$.subscribe(() => {
        this.getAllTransactions();
      });
  }

  onDeleteBorrow(transaccion: Transaction) {
    Swal.fire({
      title: `${this.messages.shure}`,
      text: `${this.messages.deleteIt} ${transaccion.description}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00AEFF',
      cancelButtonColor: '#bdbdbd',
      confirmButtonText: `${this.messages.delete}`,
      cancelButtonText: `${this.messages.cancel}`
  }).then((result) => {

      if (result.value) {
        this.dataService.deleteBorrow(transaccion.id)
        .then( () => {
          this.toast.success(`${transaccion.description} ${this.messages.deleted} `);
          this.dataService.deleteBorrowPays(transaccion.id)
        .then(() => {
          this.toast.success(`${this.messages.deletedTransactions}`);
        });
        }).catch( err => {
          this.toast.error('Error');
        });

        this.timer$.subscribe(() => {
          this.getAllTransactions();
        });
      }
    });
  }

  onDeleteBorrowPay(transaccion: Transaction) {

    Swal.fire({
      title: `${this.messages.shure}`,
      text: `${this.messages.deleteIt} ${transaccion.description}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00AEFF',
      cancelButtonColor: '#bdbdbd',
      confirmButtonText: `${this.messages.delete}`,
      cancelButtonText: `${this.messages.cancel}`
  }).then((result) => {

      if (result.value) {
        this.dataService.deleteBorrowPay(this.cardSelected.id, transaccion.id)
        .then(() => {
          this.toast.success(`${transaccion.description} ${this.messages.deleted} `);
        }).catch( err => {
          this.toast.error('Error');
        });

        this.timer$.subscribe(() => {
          this.getAllTransactions();
        });
      }
  });
  }

  onSaveBorrow() {
    if (this.newBorrow.amount < 0) {
      this.toast.error(`${this.messages.info}`);
    } else {

      const date = new Date(`${this.fecha}`);

      this.newBorrow.date_timestamp =  date;
      this.newBorrow.date =  `${this.fecha.split('-')[2]}/${this.fecha.split('-')[1]}/${this.fecha.split('-')[0]}`;
      this.dataService.addBorrow(this.newBorrow)
      .then( docRef => {
        this.toast.success(`${this.newBorrow.description} ${this.messages.added}`);
        this.eliminarDatos();
        docRef.update({
          'id': docRef.id
        });
      })
      .catch(err => {
        this.toast.error('Error');
        console.log(err);
      });
    }
  }

  cancel() {
    this.eliminarDatos();
  }

  eliminarDatos() {
    this.newBorrow.amount = null;
    this.newBorrow.description = '';
    this.fecha = null;
  }

}
