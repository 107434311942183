import { Component, OnInit, AfterContentInit, AfterViewInit, AfterViewChecked, ɵConsole} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AcountService } from 'src/app/services/acount.service';
import Swal from 'sweetalert2';
import { Transaction } from 'src/app/models/transaction';
import { es, en } from '../../../assets/i18n/component-translate';
declare var $: any;

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay
} from 'swiper/core';


SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  breakpoints = {
    300: {
      slidesPerView: 1.3,
      spaceBetween: 15
    },
    // when window width is >= 320px
    460: {
      slidesPerView: 2,
      spaceBetween: 15
    },

    // when window width is >= 640px
    900: {
      slidesPerView: 2.5,
      spaceBetween: 15
    },

    1000: {
      slidesPerView: 3,
      spaceBetween: 15
    },
    1200: {
      slidesPerView: 3.5,
      spaceBetween: 15
    }
  }

  transactions: any;
  total = null;
  user: any;

  public totalBanco = null;
  public totalahorros = null;

  totalPrestamos = null;
  totalDeudas = null;

  prestamos = null;
  deudas = null;

  tPrestamos = null;
  tDeudas = null;
  loaded = false;

  // Chart
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  public barChartLabels ;
  public barChartType = 'line';
  public barChartLegend = false;
  messages;

  constructor(
    private toast: ToastrService,
    private dataService: AcountService
    ) {
      if (window.navigator.language.split('-')[0] == 'es') {
        this.barChartLabels = ['Cartera', 'Banco', 'Ahorro', 'Prestamo', 'Deuda'];
        this.messages = es;
      } else {
        this.barChartLabels = ['Wallet', 'Bank', 'Saving', 'Lend', 'Borrow'];
        this.messages = en;
      }
  }

  ngOnInit(): void {
    $(document).ready(() => {
      $('.modal').modal({
        dismissible: false,
      });
    });

    this.getData();
    this.getUser();
    this.calcularTotalesGenerales();
  }

  getData() {
    this.dataService.getWallet().subscribe((result: any) => {
      this.transactions = result;
      console.log(this.transactions);

      this.calcularTotal();

      setTimeout(() => {
        this.loaded = true;
      }, 600);
    }, error => console.log(error));
  }

  calcularTotal() {
    this.total = 0;
    this.transactions.forEach(transaction => {
      this.total += transaction.amount;
    });
  }

  getUser() {
    this.dataService.getUserData().subscribe(response => {
      this.user = response;
    } );
  }

  calcularTotalesGenerales() {
    this.dataService.getBank().subscribe((resp: any) => {
      this.totalBanco = 0;
      for (const card of resp) {
        this.dataService.getBankPay(card.id).subscribe(response => {
          response.forEach((element: any) => {
            this.totalBanco += element.amount;
          });
        });
      }
    });

    this.dataService.getSaving().subscribe((resp: any) => {
      this.totalahorros = 0;
      for (const card of resp) {
        this.dataService.getSavingPay(card.id).subscribe(response => {
          response.forEach((element: any) => {
            this.totalahorros += element.amount;
          });
        });
      }
    });

    this.dataService.getLend().subscribe((resp: any) => {
      this.totalPrestamos = 0;
      this.prestamos = 0;
      for (const card of resp) {
        this.prestamos += card.amount;
        this.dataService.getLendPay(card.id).subscribe(response => {
              response.forEach((element: any) => {
              this.totalPrestamos += element.amount;
            });
          });
        }
    });

    this.dataService.getBorrow().subscribe((resp: any) => {
      this.totalDeudas = 0;
      this.deudas = 0;
      for (const card of resp) {
        this.deudas += card.amount;
        this.dataService.getBorrowPay(card.id).subscribe(response => {
          response.forEach((element: any) => {
            this.totalDeudas += element.amount;
          });
        });
      }
    });
  }

  onDelete(transaccion: Transaction) {
    Swal.fire ({
      title: `${this.messages.shure}`,
      text: `${this.messages.deleteIt} ${transaccion.description}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00AEFF',
      cancelButtonColor: '#bdbdbd',
      confirmButtonText: `${this.messages.delete}`,
      cancelButtonText: `${this.messages.cancel}`
    })
  .then((result) => {
      if (result.value) {
        this.dataService.deleteWalletTransaction(transaccion.id)
        .then(() => {
          this.toast.success(`${transaccion.description}  ${this.messages.deleted} `);
        })
        .catch(err => {
          this.toast.error('Error');
        });
      }
  });
  }

}
