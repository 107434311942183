export class Transaction {
  description: string;
  date: string;
  date_timestamp: Date;
  amount?: number;
  id?: string;
  t_from?: string;
  t_to?: string;


}
