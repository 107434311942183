import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login-component/login-component.component';
import { SingUpComponent } from './sing-up/sing-up.component';
import { AboutComponent } from './about/about.component';

//Transalate Modules
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

//Loader de idiomas
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// Swiper
import { SwiperModule } from 'swiper/angular';

@NgModule({
  declarations: [
    LoginComponent,
    SingUpComponent,
    AboutComponent,
  ],
  exports: [
    LoginComponent,
    SingUpComponent,
    AboutComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SwiperModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ]
})
export class AuthModule { }
