import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../models/user';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-sing-up',
  templateUrl: './sing-up.component.html',
  styleUrls: ['./sing-up.component.css']
})
export class SingUpComponent implements OnInit {

  user: User = new User();
  errorDatos = false;
  loading = false;
  fecha: number = new Date().getFullYear();


  constructor(
    private router: Router,
    private toast: ToastrService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
  }

  onSave() {
    if (!this.user.name ||  !this.user.surname || !this.user.email || !this.user.password) {
      this.errorDatos = true;
      this.toast.error('Error en los datos ingresados');
    } else {

      this.auth.registerUser(this.user).then(docRef => {
        this.toast.success(`${this.user.name} creado con éxito`);
        this.auth.loginEmailUser(this.user.email, this.user.password)
        .then((res: any) => {
          sessionStorage.setItem('uid', res.user.uid);
          this.onLoginRedirect();
          this.loading = false;
        }).catch(err => {
          console.log('err', err.message);
          this.toast.error('Error');
          this.loading = false;
        });
      });
    }
  }

  cancel() {
    this.router.navigateByUrl('/user/login');
  }

  onLoginRedirect(): void {
    this.router.navigate(['home']);
  }

}
