import { Component, OnInit} from '@angular/core';
import { User } from '../../models/user';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login-component',
  templateUrl: './login-component.component.html',
  styleUrls: ['./login-component.component.css']
})
export class LoginComponent implements OnInit {

  user = new User();
  errorCredenciales = false;
  loading = false;
  alto: any;
  rememberEmail: boolean = false;

  constructor(
    private auth: AuthService,
    private router: Router,
    private toast: ToastrService
    ) { }

  ngOnInit(): void {
    this.user.email = localStorage.getItem('email') || '';
    if(localStorage.getItem('email')) {
      this.rememberEmail = true;
    }
  }

  onLogin() {

    this.loading = true;
    if ( this.user.email && this.user.password) {

        this.auth.loginEmailUser(this.user.email, this.user.password)
          .then((res: any) => {
            localStorage.setItem('uid', res.user.uid);
            this.onLoginRedirect();
          }).catch(err => {
            console.log('err', err.message);
            this.toast.error(err.message);
            this.errorCredenciales = true;
            this.loading = false;
          } );

          if(this.rememberEmail) {
            localStorage.setItem('email', this.user.email);
          } else {
            localStorage.removeItem('email');

          }


    } else {
      this.errorCredenciales = true;
      this.loading = false;
    }

  }
      onLoginRedirect(): void {
        this.router.navigate(['home']);
      }

}
