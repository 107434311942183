import { Component, OnInit, Input } from '@angular/core';
import { Transaction } from 'src/app/models/transaction';
import { ToastrService } from 'ngx-toastr';
import { AcountService } from 'src/app/services/acount.service';
import { es, en } from '../../../../assets/i18n/component-translate';


@Component({
  selector: 'app-bank-transaction',
  templateUrl: './bank-transaction.component.html',
  styleUrls: ['./bank-transaction.component.css']
})
export class BankTransactionComponent implements OnInit {

  @Input() bank: Transaction;

  signo = false;
  transaccion = new Transaction();
  errorDatos = false;
  fecha: string;
  transac: boolean;
  transfe: boolean;
  messages;

  constructor(
    private toast: ToastrService,
    private dataService: AcountService) {
      if (window.navigator.language.split('-')[0] == 'es') {
        this.messages = es;
      } else {
        this.messages = en;
      }
    }

    ngOnInit(): void {
      this.transac = true;
      this.transfe = false;
    }

    onSave() {
      if (!this.transaccion.description || this.transaccion.amount < 0 || !this.fecha) {
        this.errorDatos = true;
        this.toast.error(`${this.messages.error}`);
      } else {
        if (this.signo) {
          this.transaccion.amount *= -1;
        }

        const date = new Date(`${this.fecha}`);

        this.transaccion.date_timestamp =  date;
        this.transaccion.date =  `${this.fecha.split('-')[2]}/${this.fecha.split('-')[1]}/${this.fecha.split('-')[0]}`;
        console.log(date);
        this.dataService.addBankPay(this.bank.id, this.transaccion)
          .then(docRef => {
            this.toast.success(`${this.transaccion.description} ${this.messages.added}`);
            this.eliminarDatos();
            docRef.update({
              'id': docRef.id
            });
          }).catch( err => {
            this.toast.error('Error');
            console.log(err);
          });
      }
    }

    cancel() {
      this.eliminarDatos();
    }

    eliminarDatos() {
      this.transaccion.amount = null;
      this.transaccion.description = '';
      this.signo = false;
      this.fecha = null;
    }

    clickTransac() {
      this.transac = true;
      this.transfe = false;
    }

    clickTransfe() {
      this.transac = false;
      this.transfe = true;
    }

  }
